export const locale = {
  lang: 'sp',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'Tableros',
        BADGE: '2',
        ANALYTICS: 'Analytik',
        ECOMMERCE: 'E-Commerce'
      },
      SETTING: {
        SECTION: 'Configuración',
        CURRENCY: {
          SECTION: 'Tipo de moneda'
        },
        WEBSERVICE: {
          SECTION: 'Servicio web'
        },
        TAXSERVICE: {
          SECTION: 'Impuesto'
        },
        PLAN: {
          SECTION: 'Plan'
        },
        BANK: {
          SECTION: 'Banco'
        },
        COUNTRY: {
          SECTION: 'País'
        },
        STATE: {
          SECTION: 'Estado'
        },
        LOCATION: {
          SECTION: 'Localidade'
        },
        MUNICIPAL: {
          SECTION: 'Municipio'
        },
        POSTCODE: {
          SECTION: 'Código postal'
        },
        COLONY: {
          SECTION: 'Colonia'
        },
        TAX_OBJECT_CATEGORY: {
          SECTION: 'Catálogo objeto impuesto'
        },
        MONTH: {
          SECTION: 'Meses'
        },
        PERIODICITY: {
          SECTION: 'Periodicidad'
        },
        RETENTION_PAYMENT_TYPE: {
          SECTION: 'Tipo pago de la retención'
        },
        YEAR: {
          SECTION: 'Año'
        },
        RELATIONSHIP_TYPE: {
          SECTION: 'Tipo de relación'
        },
        EXPORT: {
          SECTION: 'Exportación'
        },
        AGENT_NUMBER_TYPE: {
          SECTION: 'Autorización del agente'
        },
        LOAD_TYPE: {
          SECTION: 'Tipo de carga'
        },
        BOAT_TYPE: {
          SECTION: 'Tipo de embarcación'
        },
        AIR_TYPE: {
          SECTION: 'Código del transportista'
        },
        TRAILER_TYPE: {
          SECTION: 'Subtipo de remolque o semirremolque'
        },
        PERMISSION_TYPE: {
          SECTION: 'Tipo de permiso proporcionado por la SCT'
        },
        FEDERAL_TRUCKING_TYPE: {
          SECTION: 'Clave de nomenclatura del autotransporte'
        },
        PACKAGING_UNIT: {
          SECTION: 'Catálogo de unidades de medida y embalaje'
        },
        PACKAGING_TYPE: {
          SECTION: 'Catálogo de embalaje'
        },
        PRODUCT_STCC_CATEGORY: {
          SECTION: 'Catálogo de productos STCC'
        },
        HAZARDOUS_CATEGORY: {
          SECTION: 'Catálogo de materiales peligrosos'
        },
        STATION_LIST: {
          SECTION: 'Listas de estación'
        },
        STATION: {
          SECTION: 'Llave de estación'
        },
        TRANSPORT: {
          SECTION: 'Llave de transporte'
        },
        MERCHANDISE_MEASURE: {
          SECTION: 'Unidad de medida'
        },
        TRANSFR_REASON: {
          SECTION: 'Motivo de traslado'
        },
        USECFDI: {
          SECTION: 'Uso del CFDI'
        },
        PRODUCTCATALOG: {
          SECTION: 'Administrar Catalogo de producto'
        },
        MEASUREMENT: {
          SECTION: 'Measurement Unit'
        },
        ORIGINBANK: {
          SECTION: 'Bank Origin & Destination'
        },
        TEMPLATE: {
          SECTION: 'Template'
        },
        EJERCICIOFISCAL: {
          SECTION: 'Ejercicio Fiscal'
        },
        EDUCATIONLEVEL: {
          SECTION: 'Education Level'
        },
        TYPEOFOPARETION: {
          SECTION: 'Operación'
        },
        PETTIONTYPE: {
          SECTION: 'Pedimento'
        },
        INCOTERM: {
          SECTION: 'Incoterm'
        },
      },
      MASTER: {
        SECTION: 'Administrador del Usuario Principal',
        MASTER: {
          SECTION: 'Usuarios'
        },
        EXPIRY_MASTER: {
          SECTION: 'Usuarios vencidos'
        }
      },
      EMPLOYEE: {
        SECTION: 'Administración de Empleados',
        EMPLOYEE_JOB_TYPE: {
          SECTION: 'Puesto del Empleado'
        },
        EMPLOYEE_EXTRA_HOUR: {
          SECTION: 'Horas Extra del Empleado'
        },
        EMPLOYEEPAYMENTPERIODTYPE: {
          SECTION: 'Periodicidad de pago del Empleado'
        },
        EMPLOYEEJOBRISKTYPE: {
          SECTION: 'Riesgo de trabajo del Empleado'
        },
        EMPLOYEEOFFICIALJOBTYPE: {
          SECTION: 'Puesto Oficial del Empleado'
        },
        EMPLOYEEEXPENSETYPE: {
          SECTION: 'Percepciones del Empleado'
        },
        EMPLOYEECONTRACTTYPE: {
          SECTION: 'Tipo de contrato del Empleado'
        },
        EMPLOYEEDISABILITYTYPE: {
          SECTION: 'Incapacidades del empleado'
        },
        EMPLOYEEOTHERTYPE: {
          SECTION: 'Otro Tipo de Pago'
        },
        EMPLOYEEDISCOUNTTYPE: {
          SECTION: 'Deducciones del empleado'
        },
        EMPLOYEE: {
          SECTION: 'Empleado'
        },
        DEPARTMENT: {
          SECTION: 'Departamento'
        },
        EXPENSE: {
          SECTION: 'Percepciones del Empleado'
        },
        DISCOUNT: {
          SECTION: 'Deducciones del empleado'
        },
        OTHER: {
          SECTION: 'Otros pagos del empleado'
        }
      },
      RETENTION: {
        SECTION: 'Administración Retención',
        RETENTION_KEY: {
          SECTION: 'Retención Tipo de ingreso distribuido'
        },
        DISTRIBUTEDINCOMETYPE: {
          SECTION: 'Retención Tipo de ingreso distribuido'
        },
        FEDERALENTITY: {
          SECTION: 'Entidad Federal de Retención'
        },
        RETENTIONTAXTYPE: {
          SECTION: 'Tipo de Impuesto de Retención'
        },
        TAXPAYERSUBJECTTYPE: {
          SECTION: 'Tipo de sujeto del contribuyente de retención'
        },
        RETENTION_MODULE: 'Administración Retención 2.0',
        RETENTION: 'Gestion de retenciones 2.0',
        RETENTION_DRAFT: 'Borradores Retenciones 2.0',
        CANCELLED_RETENTION: 'Retenciones Canceladas 2.0',
        FAILED_RETENTION: 'Intentos Fallidos Retenciones 2.0',
        RETENTION_BATCH_MODULE: 'Retencion Batch 2.0',
        RETENTION_BATCH: 'Retencion Batch 2.0',
        RETENTION_BATCH_ADD: 'Nueva Constancia Batch 2.0',
        CANCELLED_RETENTION_BATCH: 'Cancelaciones Batch 2.0',
        FAILED_RETENTION_BATCH: 'Intentos Fallidos Batch 2.0',
        RETENTION_RECEPTOR: 'Receptores Retenciones',
        RETENTION_NEW: 'Nueva Constancia 2.0'
      },
      COMPANY: {
        SECTION: 'Empresa'
      },
      CUSTOMER: {
        SECTION: 'Cliente'
      },
      PRODUCT: {
        SECTION: 'Productos'
      },
      PART: {
        SECTION: 'Parte'
      },
      TAX: {
        SECTION: 'Administrar impuestos'
      },
      ADDENDA: {
        SECTION: 'Addenda'
      },
      INVOICE_PREVIOUS: {
        INVOICE_MODULE: 'Gestión de Facturas 3.3',
        INVOICE: 'Gestión de Facturas 3.3',
        LATEST_INVOICE: 'Gestión de Facturas CMV 3.3',
        LATEST_INVOICE_DRAFT: 'Borradores Facturas CMV 3.3',
        INVOICE_BATCH: 'Facturas Batch 3.3',
        INVOICE_BATCH_MODULE: 'Factura Batch 3.3',
        INVOICE_DRAFT: 'Borradores Facturas 3.3',
        CANCELLED_INVOICE: 'Cancelaciones Facturas 3.3',
        CANCELLED_LATEST_INVOICE: 'Cancelaciones Facturas CMV 3.3',
        FAILED_INVOICE: 'Intentos Fallidos Facturas 3.3',
        FAILED_LATEST_INVOICE: 'Fallidos Facturas CMV 3.3',
        CANCELLED_INVOICE_BATCH: 'Cancelaciones Batch 3.3',
        FAILED_INVOICE_BATCH: 'Intentos Fallidos Batch 3.3',
        BULK_INVOICE: 'Descarga Facturas Web',
        BULK_INVOICE_BATCH: 'Descarga Facturas Batch',
        ADDENDA: 'Administración de Addenda',
        INVOICE_REPORT: 'Reporte Facturas Web',
        INVOICE_BATCH_REPORT: 'Reporte Facturas Batch',
        INVOICE_PAYMENT_REPORT: 'Reporte Recibo Electrónico de Pago Web',
        INVOICE_PAYMENT_BATCH_REPORT: 'Reporte Recibo Electrónico de Pago Batch',
        INVOICE_PAYMENT_MENU: 'Reporte Factura Pagos Web',
        INVOICE_PREMIUM_MENU: 'Reporte Plus Pagos Web',
        INVOICE_PREMIUM_BATCH_MENU: 'Reporte Plus Pagos Batch',
        INVOICE_PAYMENT_BATCH_MENU: 'Reporte Factura Pagos Batch',
        NEW_INVOICE: 'Nueva Factura',
        NEW_TAX_INVOICE: 'Nuevo Batch múltiples impuestos',
        NEW_BATCH: 'Nueva Factura Batch',
        NEW_STATIC_BATCH: 'Nuevo Batch Importes manuales',
        GET_CFDI_RESPONSE: 'Estatus del CFDI',
        TITLE: 'Factura',
        ADD_TITLE: 'Crear Factura',
        EDIT_TITLE: 'Modificar Factura',
        DELETE_TITLE: 'Borrar Factura',
        CANCEL_CONFIRMATION: 'Estatus del CFDI',
        PAID_TITLE: 'Pagada Factura',
        MANAGE_TITLE: 'Administrar',
        DRAFT_TITLE: 'Borradores Factura',
      },
      INVOICE: {
        INVOICE_MODULE: 'Gestión de Facturas 4.0',
        INVOICE: 'Gestión de Facturas 4.0',
        LATEST_INVOICE: 'Gestión de Facturas CMV 4.0',
        LATEST_INVOICE_DRAFT: 'Borradores Facturas CMV 4.0',
        INVOICE_BATCH: 'Facturas Batch',
        INVOICE_BATCH_ADD: 'Nueva Factura Batch 4.0',
        INVOICE_BATCH_IEPS_ADD: 'Nueva Factura IEPS Batch 4.0',
        INVOICE_BATCH_MODULE: 'Factura Batch 4.0',
        INVOICE_DRAFT: 'Borradores Facturas 4.0',
        CANCELLED_INVOICE: 'Cancelaciones Facturas 4.0',
        CANCELLED_LATEST_INVOICE: 'Cancelaciones Facturas CMV 4.0',
        FAILED_INVOICE: 'Intentos Fallidos Facturas 4.0',
        FAILED_LATEST_INVOICE: 'Fallidos Facturas CMV 4.0',
        CANCELLED_INVOICE_BATCH: 'Cancelaciones Batch',
        FAILED_INVOICE_BATCH: 'Intentos Fallidos Batch',
        BULK_INVOICE: 'Descarga Facturas Web',
        BULK_INVOICE_BATCH: 'Descarga Facturas Batch',
        ADDENDA: 'Administración de Addenda',
        INVOICE_REPORT: 'Reporte Facturas Web',
        INVOICE_BATCH_REPORT: 'Reporte Facturas Batch',
        INVOICE_PAYMENT_REPORT: 'Reporte Recibo Electrónico de Pago Web',
        INVOICE_PAYMENT_BATCH_REPORT: 'Reporte Recibo Electrónico de Pago Batch',
        INVOICE_PAYMENT_MENU: 'Reporte Factura Pagos Web',
        INVOICE_PREMIUM_MENU: 'Reporte Plus Pagos Web',
        INVOICE_PREMIUM_BATCH_MENU: 'Reporte Plus Pagos Batch',
        INVOICE_PAYMENT_BATCH_MENU: 'Reporte Factura Pagos Batch',
        NEW_INVOICE: 'Nueva Factura',
        NEW_TAX_INVOICE: 'Nuevo Batch múltiples impuestos',
        NEW_BATCH: 'Nueva Factura Batch',
        NEW_STATIC_BATCH: 'Nuevo Batch Importes manuales',
        GET_CFDI_RESPONSE: 'Estatus del CFDI',
        TITLE: 'Factura',
        ADD_TITLE: 'Crear Factura',
        EDIT_TITLE: 'Modificar Factura',
        DELETE_TITLE: 'Borrar Factura',
        CANCEL_CONFIRMATION: 'Estatus del CFDI',
        PAID_TITLE: 'Pagada Factura',
        MANAGE_TITLE: 'Administrar',
        DRAFT_TITLE: 'Borradores Factura',
      },
      INVOICE_PERCENT: {
        INVOICE_MODULE: 'Gestión de Facturas IEPS 4.0',
        INVOICE: 'Gestión de Facturas IEPS 4.0',
        INVOICE_DRAFT: 'Borradores Facturas IEPS 4.0',
        CANCELLED_INVOICE: 'Cancelaciones Facturas IEPS 4.0',
        FAILED_INVOICE: 'Intentos Fallidos Facturas IEPS 4.0',
      },
      PAYROLL: {
        PAYROLL_MODULE: 'Gestión de nómina 4.0',
        PAYROLL: 'Recibo de Nómina 4.0',
        PAYROLL_DRAFT: 'Borradores Nómina 4.0',
        CANCELLED_PAYROLL: 'Nómina Cancelada 4.0',
        FAILED_PAYROLL: 'Intentos Fallidos Nómina 4.0',
        PAYROLL_BATCH_MODULE: 'Nómina Batch 4.0',
        PAYROLL_BATCH: 'Nómina Batch 4.0',
        PAYROLL_BATCH_ADD: 'Nuevo Recibo Batch 4.0',
        PAYROLL_BATCH_CANCELLED: 'Cancelaciones Batch 4.0',
        PAYROLL_BATCH_FAILED: 'Intentos Fallidos Batch 4.0',
        NEW_PAYROLL: 'Nuevo Recibo 4.0'
      },
      PAYROLL_PREVIOUS: {
        PAYROLL_MODULE: 'Gestión de nómina 3.3',
        PAYROLL: 'Recibo de Nómina 3.3',
        PAYROLL_DRAFT: 'Borradores Nómina 3.3',
        CANCELLED_PAYROLL: 'Nómina Cancelada 3.3',
        FAILED_PAYROLL: 'Intentos Fallidos Nómina 3.3',
        PAYROLL_BATCH_MODULE: 'Nómina Batch 3.3',
        PAYROLL_BATCH: 'Nómina Batch 3.3',
        PAYROLL_BATCH_CANCELLED: 'Cancelaciones Batch 3.3',
        PAYROLL_BATCH_FAILED: 'Intentos Fallidos Batch 3.3',
        NEW_PAYROLL: 'Nuevo Recibo 3.3',
      },
      PAYROLL_MULTIPLE: {
        PAYROLL_MODULE: 'Nómina múltiples complementos 4.0',
        PAYROLL: 'Gestion múltiples complementos 4.0',
        PAYROLL_DRAFT: 'Borradores nómina 4.0',
        CANCELLED_PAYROLL: 'Nómina cancelada 4.0',
        FAILED_PAYROLL: 'Intentos fallidos nómina 4.0',
        PAYROLL_BATCH_MODULE: 'Nómina global batch 4.0',
        PAYROLL_BATCH: 'Gestión nómina global batch',
        PAYROLL_BATCH_ADD: 'Nuevo recibo global batch',
        PAYROLL_BATCH_CANCELLED: 'Cancelaciones nómina global batch',
        PAYROLL_BATCH_FAILED: 'Fallidos nómina global batch',
        NEW_PAYROLL: 'Nuevo recibo global batch',
      },
      RETENTION_PREVIOUS: {
        RETENTION_MODULE: 'Administración Retención 1.0',
        RETENTION: 'Gestion de retenciones 1.0',
        RETENTION_DRAFT: 'Borradores Retenciones 1.0',
        CANCELLED_RETENTION: 'Retenciones Canceladas 1.0',
        FAILED_RETENTION: 'Intentos Fallidos Retenciones 1.0',
        RETENTION_BATCH_MODULE: 'Retencion Batch 1.0',
        RETENTION_BATCH: 'Retencion Batch 1.0',
        CANCELLED_RETENTION_BATCH: 'Cancelaciones Batch 1.0',
        FAILED_RETENTION_BATCH: 'Intentos Fallidos Batch 1.0',
      },
      CUSTOMER_REPORT: {
        REPORT: 'Reportes',
        PAYROLL_BATCH_REPORT: 'Reporte nómina batch',
        PAYROLL_REPORT: 'Reporte nómina Web',
        MULTIPLE_PAYROLL_BATCH_REPORT: 'Reporte nómina global batch',
        MULTIPLE_PAYROLL_REPORT: 'Reporte nómina global web',
        RETENTION_BATCH_REPORT: 'Reporte retenciones batch',
        RETENTION_REPORT: 'Reporte retenciones web',
        INVOICE_BATCH_REPORT: 'Reporte facturas batch',
        INVOICE_REPORT: 'Reporte facturas web',
        INVOICE_PAYMENT_BATCH_REPORT: 'Reporte Factura pagos batch',
        INVOICE_PAYMENT_REPORT: 'Reporte Factura pagos web',
        INVOICE_PREMIUM_BATCH_REPORT: 'Reporte plus pagos batch',
        INVOICE_PREMIUM_REPORT: 'Reporte plus pagos web',
        DOWNLOAD_INVOICE_TITLE: 'Descarga facturas web',
        DOWNLOAD_INVOICE_BATCH_TITLE: 'Descarga facturas batch',
        DOWNLOAD_PAYROLL_TITLE: 'Descarga nómina web',
        DOWNLOAD_PAYROLL_BATCH_TITLE: 'Descarga nómina batch',
        DOWNLOAD_RETENTION_TITLE: 'Descarga retenciones web',
        DOWNLOAD_RETENTION_BATCH_TITLE: 'Descarga retenciones batch',
      },
      OPERATOR: {
        TITLE: 'Administrador de Usuarios'
      },
      AUTOMATIC: {
        SECTION: 'FACTURA AUTOMÁTICA',
        LIST: {
          SECTION: 'Gestión F.A.'
        },
        FAILED_LIST: {
          SECTION: 'Fallidos F.A.'
        },
        CANCELLED_LIST: {
          SECTION: 'Cancelaciones F.A.'
        }
      },
      REPORT: {
        SECTION: 'Reportes',
        AUTOMATIC_INVOICE_REPORT: {
          SECTION: 'Reporte F.A.'
        },
        AUTOMIATIC_INVOICE_PAYMENT_REPORT: {
          SECTION: 'Reporte pago F.A.'
        },
        AUTOMATIC_INVOICE_DOWNLOAD: {
          SECTION: 'Descarga F.A.'
        },
        AUTOMATIC_INVOICE_FAILED_REPORT: {
          SECTION: 'Report Fallidos F.A.'
        },
        AUTOMATIC_INVOICE_SPECIAL_REPORT: {
          SECTION: 'Reporte especial'
        }
      },
      APPS: {
        SECTION: 'Apps & Seiten',
        EMAIL: 'Correo electrónico',
        CHAT: 'Plaudern',
        TODO: 'Machen',
        CALENDAR: 'Calendario',
        INVOICE: {
          COLLAPSIBLE: 'Rechnung',
          LIST: 'Liste',
          PREVIEW: 'Vorschau',
          EDIT: 'Bearbeiten',
          ADD: 'Hinzufügen'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'E-Commerce',
          SHOP: 'Geschäft',
          DETAIL: 'Einzelheiten',
          WISHLIST: 'Wunschzettel',
          CHECKOUT: 'Überprüfen'
        },
        USER: {
          COLLAPSIBLE: 'Nutzer',
          LIST: 'Liste',
          VIEW: 'Aussicht',
          EDIT: 'Bearbeiten'
        }
      },
      PAGES: {
        SECTION: 'Seiten',
        AUTH: {
          COLLAPSIBLE: 'Authentifizierung',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Passwort vergessen V1',
          FORGOTPASSWORD2: 'Passwort vergessen V2',
          RESETPASSWORD1: 'Passwort zurücksetzen V1',
          RESETPASSWORD2: 'Passwort zurücksetzen V2'
        },
        ACCOUNTSETTINGS: 'Kontoeinstellungen',
        PROFILE: 'Profil',
        FAQ: 'FAQ',
        KB: 'Wissensbasis',
        PRICING: 'Preisgestaltung',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'Liste',
          DETAILS: 'Einzelheiten',
          EDIT: 'Bearbeiten'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail-Vorlage',
          WELCOME: 'Herzlich willkommen',
          RESET: 'Passwort zurücksetzen',
          VERIFY: 'Überprüfen',
          DEACTIVATE: 'Deaktivieren',
          INVOICE: 'Rechnung',
          PROMOTIONAL: 'Werbung'
        },
        MISC: {
          COLLAPSIBLE: 'Verschiedenes',
          COMINGSOON: 'Kommt bald',
          NOTAUTH: 'Nicht genehmigt',
          MAINTENANCE: 'Instandhaltung',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'Benutzeroberfläche',
        TYPOGRAPHY: 'Typografie',
        COLORS: 'Farben',
        FEATHER: 'Feder',
        CARDS: {
          COLLAPSIBLE: 'Karten',
          BADGE: 'Neu',
          BASIC: 'Basic',
          ADVANCE: 'Voraus',
          STATISTICS: 'Statistiken',
          ANALYTICS: 'Analytik',
          ACTIONS: 'Aktionen'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Komponenten',
          ALERTS: 'Warnungen',
          AVATAR: 'Benutzerbild',
          BADGES: 'Abzeichen',
          BREADCRUMBS: 'Semmelbrösel',
          BUTTONS: 'Tasten',
          CAROUSEL: 'Karussell',
          COLLAPSE: 'Zusammenbruch',
          DIVIDER: 'Teiler',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'Listengruppe',
          OBJECTS: 'Medienobjekte',
          MODALS: 'Modale',
          COMPONENT: 'Navs',
          PAGINATION: 'Seitennummerierung',
          PBADGES: 'Pillenabzeichen',
          PILLS: 'Pillen',
          POPOVERS: 'Popovers',
          PROGRESS: 'Fortschritt',
          RATINGS: 'Bewertungen',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Zeitleiste',
          TOASTS: 'Toast',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Erweiterung',
          SWEET_ALERTS: 'Süße Warnungen',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Schieberegler',
          DRAGDROP: 'Ziehen und loslassen',
          TOUR: 'Tour',
          CLIPBOARD: 'Zwischenablage',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Kontextmenü',
          SWIPER: 'Swiper',
          TREEVIEW: 'Baumsicht',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Seitenlayouts',
          COLLAPSED_MENU: 'Reduziertes Menü',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Ohne Menü',
          LAYOUT_EMPTY: 'Layout leer',
          LAYOUT_BLANK: 'Layout leer'
        }
      },
      FT: {
        SECTION: 'Formulare & Tabellen',
        ELEMENT: {
          COLLAPSIBLE: 'Formularelemente',
          INPUT: 'Eingang',
          INPUTGROUPS: 'Eingabegruppen',
          INPUTMASK: 'Eingabemaske',
          TEXTAREA: 'Textbereich',
          CHECKBOX: 'Kontrollkästchen',
          RADIO: 'Radio',
          SWITCH: 'Schalter',
          SELECT: 'Wählen',
          NUMBERINPUT: 'Zahleneingabe',
          FILEUPLOADER: 'Datei-Uploader',
          QUILLEDITOR: 'Federkiel-Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Datums- und Zeitauswahl'
        },
        LAYOUTS: 'Formularlayouts',
        WIZARD: 'Formzauberer',
        VALIDATION: 'Formularvalidierungen',
        REPEATER: 'Form Repeater',
        TABLE: 'Tabelle',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Diagramme & Karten',
        CHARTS: {
          COLLAPSIBLE: 'Diagramme',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Andere',
        LEVELS: {
          COLLAPSIBLE: 'Menüebenen',
          SECOND: 'Zweites Level',
          SECOND1: {
            COLLAPSIBLE: 'Zweites Level',
            THIRD: 'Drittes Level',
            THIRD1: 'Drittes Level'
          }
        },
        DISABLED: 'Deaktiviertes Menü',
        DOCUMENTATION: 'Dokumentation',
        SUPPORT: 'Unterstützung erhöhen'
      }
    }
  }
};
