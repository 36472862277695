import { CoreMenu } from '@core/types';

// ? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface
export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'config',
    type: 'collapsible',
    title: 'Configuration',
    translate: 'MENU.SETTING.SECTION',
    icon: 'settings',
    role: ['Admin'],
    children: [
      {
        id: 'currency',
        title: 'Tipo de moneda',
        translate: 'MENU.SETTING.CURRENCY.SECTION',
        type: 'item',
        url: 'setting/currency'
      },
      {
        id: 'web-service',
        title: 'Servicio web',
        translate: 'MENU.SETTING.WEBSERVICE.SECTION',
        type: 'item',
        url: 'setting/web-service'
      },
      {
        id: 'tax-service',
        title: 'Impuesto',
        translate: 'MENU.SETTING.TAXSERVICE.SECTION',
        type: 'item',
        url: 'setting/tax-service'
      }, {
        id: 'plan',
        title: 'Plan',
        translate: 'MENU.SETTING.PLAN.SECTION',
        type: 'item',
        url: 'setting/plan'
      }, {
        id: 'bank',
        title: 'Banco',
        translate: 'MENU.SETTING.BANK.SECTION',
        type: 'item',
        url: 'setting/bank'
      },
      {
        id: 'country',
        title: 'País',
        translate: 'MENU.SETTING.COUNTRY.SECTION',
        type: 'item',
        url: 'setting/country'
      },
      {
        id: 'state',
        title: 'Estado',
        translate: 'MENU.SETTING.STATE.SECTION',
        type: 'item',
        url: 'setting/state'
      },
      {
        id: 'location',
        title: 'Localidade',
        translate: 'MENU.SETTING.LOCATION.SECTION',
        type: 'item',
        url: 'setting/location'
      },
      {
        id: 'municipality',
        title: 'Municipio',
        translate: 'MENU.SETTING.MUNICIPAL.SECTION',
        type: 'item',
        url: 'setting/municipality'
      },
      {
        id: 'postcode',
        title: 'Código postal',
        translate: 'MENU.SETTING.POSTCODE.SECTION',
        type: 'item',
        url: 'setting/postalcode'
      },
      {
        id: 'colony',
        title: 'Colonia',
        translate: 'MENU.SETTING.COLONY.SECTION',
        type: 'item',
        url: 'setting/colony'
      },
      {
        id: 'tax-object-category',
        title: 'Catálogo Objeto impuesto',
        translate: 'MENU.SETTING.TAX_OBJECT_CATEGORY.SECTION',
        type: 'item',
        url: 'setting/tax-object-category'
      },
      {
        id: 'use-cfdi',
        title: 'Uso del CFDI',
        translate: 'MENU.SETTING.USECFDI.SECTION',
        type: 'item',
        url: 'setting/use-cfdi'
      },
      {
        id: 'product-catalog',
        title: 'Administrar Catalogo de producto',
        translate: 'MENU.SETTING.PRODUCTCATALOG.SECTION',
        type: 'item',
        url: 'setting/product-catalog'
      },
      {
        id: 'measurement',
        title: 'Measurement Unit',
        translate: 'MENU.SETTING.MEASUREMENT.SECTION',
        type: 'item',
        url: 'setting/measurement'
      },
      {
        id: 'origin-bank',
        title: 'Bank Origin & Destination',
        translate: 'MENU.SETTING.ORIGINBANK.SECTION',
        type: 'item',
        url: 'setting/origin-bank'
      },
      {
        id: 'template',
        title: 'Template',
        translate: 'MENU.SETTING.TEMPLATE.SECTION',
        type: 'item',
        url: 'setting/template'
      },
      {
        id: 'incoterm',
        title: 'Incoterm',
        translate: 'MENU.SETTING.INCOTERM.SECTION',
        type: 'item',
        url: 'setting/incoterm'
      },
      {
        id: 'ejerciciofiscal',
        title: 'Ejercicio Fiscal',
        translate: 'MENU.SETTING.EJERCICIOFISCAL.SECTION',
        type: 'item',
        url: 'setting/fiscal-exercise'
      },
      {
        id: 'education-level',
        title: 'Education Level',
        translate: 'MENU.SETTING.EDUCATIONLEVEL.SECTION',
        type: 'item',
        url: 'setting/education-level'
      },
      {
        id: 'types-of-operation',
        title: 'Operación',
        translate: 'MENU.SETTING.TYPEOFOPARETION.SECTION',
        type: 'item',
        url: 'setting/operation'
      },
      {
        id: 'petition',
        title: 'Pedimento',
        translate: 'MENU.SETTING.PETTIONTYPE.SECTION',
        type: 'item',
        url: 'setting/petition'
      },
      {
        id: 'month',
        title: 'Meses',
        translate: 'MENU.SETTING.MONTH.SECTION',
        type: 'item',
        url: 'setting/month'
      },
      {
        id: 'periodicity',
        title: 'Periodicidad',
        translate: 'MENU.SETTING.PERIODICITY.SECTION',
        type: 'item',
        url: 'setting/periodicity'
      },
      {
        id: 'retention-payment-type',
        title: 'Tipo pago de la retención',
        translate: 'MENU.SETTING.RETENTION_PAYMENT_TYPE.SECTION',
        type: 'item',
        url: 'setting/retention-payment-type'
      },
      {
        id: 'year',
        title: 'Año',
        translate: 'MENU.SETTING.YEAR.SECTION',
        type: 'item',
        url: 'setting/year'
      },
      {
        id: 'relationship-type',
        title: 'Tipo de relación',
        translate: 'MENU.SETTING.RELATIONSHIP_TYPE.SECTION',
        type: 'item',
        url: 'setting/relationship-type'
      },
      {
        id: 'export',
        title: 'Export',
        translate: 'MENU.SETTING.EXPORT.SECTION',
        type: 'item',
        url: 'setting/export'
      },
      {
        id: 'agent-number-type',
        title: 'Autorización del agente',
        translate: 'MENU.SETTING.AGENT_NUMBER_TYPE.SECTION',
        type: 'item',
        url: 'setting/agent-number-type'
      },
      {
        id: 'load-type',
        title: 'Tipo de carga',
        translate: 'MENU.SETTING.LOAD_TYPE.SECTION',
        type: 'item',
        url: 'setting/load-type'
      },
      {
        id: 'boat-type',
        title: 'Tipo de embarcación',
        translate: 'MENU.SETTING.BOAT_TYPE.SECTION',
        type: 'item',
        url: 'setting/boat-type'
      },
      {
        id: 'trailer-type',
        title: 'Subtipo de remolque o semirremolque',
        translate: 'MENU.SETTING.TRAILER_TYPE.SECTION',
        type: 'item',
        url: 'setting/trailer-type'
      },
      {
        id: 'permission-type',
        title: 'Tipo de permiso proporcionado por la SCT',
        translate: 'MENU.SETTING.PERMISSION_TYPE.SECTION',
        type: 'item',
        url: 'setting/permission-type'
      },
      {
        id: 'federal-trucking-type',
        title: 'Clave de nomenclatura del autotransporte',
        translate: 'MENU.SETTING.FEDERAL_TRUCKING_TYPE.SECTION',
        type: 'item',
        url: 'setting/federal-trucking-type'
      },
      {
        id: 'packaging-unit',
        title: 'Catálogo de unidades de medida y embalaje',
        translate: 'MENU.SETTING.PACKAGING_UNIT.SECTION',
        type: 'item',
        url: 'setting/packaging-unit'
      },
      {
        id: 'packaging-type',
        title: 'Catálogo de embalaje',
        translate: 'MENU.SETTING.PACKAGING_TYPE.SECTION',
        type: 'item',
        url: 'setting/packaging-type'
      },
      {
        id: 'product-stcc-category',
        title: 'Catálogo de productos STCC',
        translate: 'MENU.SETTING.PRODUCT_STCC_CATEGORY.SECTION',
        type: 'item',
        url: 'setting/product-stcc-category'
      },
      {
        id: 'hazardous-category',
        title: 'Catálogo de materiales peligrosos',
        translate: 'MENU.SETTING.HAZARDOUS_CATEGORY.SECTION',
        type: 'item',
        url: 'setting/hazardous-category'
      },
      {
        id: 'station-list',
        title: 'Listas de estación',
        translate: 'MENU.SETTING.STATION_LIST.SECTION',
        type: 'item',
        url: 'setting/station-list'
      },
      {
        id: 'station',
        title: 'Llave de estación',
        translate: 'MENU.SETTING.STATION.SECTION',
        type: 'item',
        url: 'setting/station'
      },
      {
        id: 'transport',
        title: 'Llave de transporte',
        translate: 'MENU.SETTING.TRANSPORT.SECTION',
        type: 'item',
        url: 'setting/transport'
      },
      {
        id: 'merchandise-measure',
        title: 'Unidad de medida aplicable a la mercancia',
        translate: 'MENU.SETTING.MERCHANDISE_MEASURE.SECTION',
        type: 'item',
        url: 'setting/merchandise-measure'
      },
      {
        id: 'transfer-reason',
        title: 'Motivo de traslado',
        translate: 'MENU.SETTING.TRANSFR_REASON.SECTION',
        type: 'item',
        url: 'setting/transfer-reason'
      },
    ]
  },
  {
    id: 'company-containers',
    title: 'Empresa',
    translate: 'MENU.COMPANY.SECTION',
    type: 'item',
    icon: 'package',
    url: 'company/company',
    role: ['Admin'],
  },
  {
    id: 'master-containers',
    type: 'collapsible',
    title: 'Administrador del Usuario Principal',
    translate: 'MENU.MASTER.SECTION',
    icon: 'user',
    role: ['Admin'],
    children: [
      {
        id: 'master',
        title: 'Usuarios',
        translate: 'MENU.MASTER.MASTER.SECTION',
        type: 'item',
        url: 'master/master'
      },
      {
        id: 'expired-master',
        title: 'Usuarios vencidos',
        translate: 'MENU.MASTER.EXPIRY_MASTER.SECTION',
        type: 'item',
        url: 'master/expiry-master'
      }
    ]
  },
  {
    id: 'employee',
    type: 'collapsible',
    title: 'Administrador del Usuario Principal',
    translate: 'MENU.EMPLOYEE.SECTION',
    icon: 'settings',
    role: ['Admin'],
    children: [
      {
        id: 'employee-job-type',
        title: 'Puesto del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEE_JOB_TYPE.SECTION',
        type: 'item',
        url: 'employee/employee-job-type'
      },
      {
        id: 'employee-extra-hour',
        title: 'Horas Extra del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEE_EXTRA_HOUR.SECTION',
        type: 'item',
        url: 'employee/employee-extra-hour'
      },
      {
        id: 'employee-payment-period-type',
        title: ' Periodicidad de pago del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEPAYMENTPERIODTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-payment-period-type'
      },
      {
        id: 'employee-job-risk-type',
        title: 'Riesgo de trabajo del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEJOBRISKTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-job-risk-type'
      },
      {
        id: 'employee-official-job-type',
        title: 'Puesto Oficial del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEOFFICIALJOBTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-official-job-type'
      },
      {
        id: 'employee-expense-type',
        title: 'Percepciones del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEEXPENSETYPE.SECTION',
        type: 'item',
        url: 'employee/employee-expense-type'
      },
      {
        id: 'employee-contract-type',
        title: 'Tipo de contrato del Empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEECONTRACTTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-contract-type'
      },
      {
        id: 'employee-disability-type',
        title: 'Incapacidades del empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEDISABILITYTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-disability-type'
      },
      {
        id: 'employee-other-type',
        title: 'Otro Tipo de Pago',
        translate: 'MENU.EMPLOYEE.EMPLOYEEOTHERTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-other-type'
      },
      {
        id: 'employee-discount-type',
        title: 'Deducciones del empleado',
        translate: 'MENU.EMPLOYEE.EMPLOYEEDISCOUNTTYPE.SECTION',
        type: 'item',
        url: 'employee/employee-discount-type'
      }
    ]
  },
  {
    id: 'retention',
    type: 'collapsible',
    title: 'Administración Retención',
    translate: 'MENU.RETENTION.SECTION',
    icon: 'file-text',
    role: ['Admin'],
    children: [
      {
        id: 'retention-key',
        title: 'Retención Tipo de ingreso distribuido',
        translate: 'MENU.RETENTION.RETENTION_KEY.SECTION',
        type: 'item',
        url: 'retention/retention-key'
      },
      {
        id: 'distributed-income-type',
        title: 'Retención Tipo de ingreso distribuido',
        translate: 'MENU.RETENTION.DISTRIBUTEDINCOMETYPE.SECTION',
        type: 'item',
        url: 'retention/distributed-income-type'
      },
      {
        id: 'federal-entity',
        title: 'Entidad Federal de Retención',
        translate: 'MENU.RETENTION.FEDERALENTITY.SECTION',
        type: 'item',
        url: 'retention/federal-entity'
      },
      {
        id: 'retention-tax-type',
        title: 'Tipo de Impuesto de Retención',
        translate: 'MENU.RETENTION.RETENTIONTAXTYPE.SECTION',
        type: 'item',
        url: 'retention/retention-tax-type'
      },
      {
        id: 'taxpayer-subject-type',
        title: 'Tipo de sujeto del contribuyente de retención',
        translate: 'MENU.RETENTION.TAXPAYERSUBJECTTYPE.SECTION',
        type: 'item',
        url: 'retention/taxpayer-subject-type'
      }
    ]
  },
  {
    id: 'automatic',
    type: 'collapsible',
    title: 'FACTURA AUTOMÁTICA',
    translate: 'MENU.AUTOMATIC.SECTION',
    icon: 'package',
    role: ['Admin'],
    children: [
      {
        id: 'automatic-invoices',
        title: 'Gestión F.A.',
        translate: 'MENU.AUTOMATIC.LIST.SECTION',
        type: 'item',
        url: 'automatic-invoices/lists'
      },
      {
        id: 'automatic-invoices',
        title: 'Fallidos F.A.',
        translate: 'MENU.AUTOMATIC.FAILED_LIST.SECTION',
        type: 'item',
        url: 'automatic-invoices/failed-lists'
      },
      {
        id: 'automatic-invoices',
        title: 'Cancelaciones F.A.',
        translate: 'MENU.AUTOMATIC.CANCELLED_LIST.SECTION',
        type: 'item',
        url: 'automatic-invoices/cancelled-lists'
      }
    ]
  },
  {
    id: 'previous-invoice',
    type: 'collapsible',
    title: 'Gestión de Facturas 3.3',
    translate: 'MENU.INVOICE_PREVIOUS.INVOICE_MODULE',
    icon: 'file-text',
    role: ['Master', 'InvoiceWebUser', 'MasterUser'],
    children: [
      {
        id: 'invoice/invoice-v-3',
        title: 'Gestión de Facturas 3.3',
        translate: 'MENU.INVOICE_PREVIOUS.INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-3'
      },
      {
        id: 'invoice/invoice-v-3-drafts',
        title: 'Borradores Facturas 3.3',
        translate: 'MENU.INVOICE_PREVIOUS.INVOICE_DRAFT',
        type: 'item',
        url: 'invoice/invoice-v-3-drafts'
      },
      {
        id: 'invoice/invoice-v-3-cancelled',
        title: 'Cancelaciones Facturas 3.3',
        translate: 'MENU.INVOICE_PREVIOUS.CANCELLED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-3-cancelled'
      },
      {
        id: 'invoice/invoice-v-3-failed',
        title: 'Intentos Fallidos Facturas 3.3',
        translate: 'MENU.INVOICE_PREVIOUS.FAILED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-3-failed'
      }
    ]
  },
  {
    id: 'invoice',
    type: 'collapsible',
    title: 'Gestión de Facturas',
    translate: 'MENU.INVOICE.INVOICE_MODULE',
    icon: 'file-text',
    role: ['Master', 'InvoiceWebUser', 'MasterUser'],
    children: [
      {
        id: 'invoice/invoice',
        title: 'Nueva factura360',
        translate: 'MENU.INVOICE.NEW_INVOICE',
        type: 'item',
        url: 'invoice/invoice/add'
      },
      {
        id: 'invoice/invoice-v-4',
        title: 'Gestión de Facturas',
        translate: 'MENU.INVOICE.INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-4'
      },
      {
        id: 'invoice/invoice-v-4-drafts',
        title: 'Borradores Facturas',
        translate: 'MENU.INVOICE.INVOICE_DRAFT',
        type: 'item',
        url: 'invoice/invoice-v-4-drafts'
      },
      {
        id: 'invoice/invoice-v-4-cancelled',
        title: 'Cancelaciones Facturas',
        translate: 'MENU.INVOICE.CANCELLED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-4-cancelled'
      },
      {
        id: 'invoice/invoice-v-4-failed',
        title: 'Intentos Fallidos Facturas',
        translate: 'MENU.INVOICE.FAILED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-v-4-failed'
      }
    ]
  },
  {
    id: 'invoice-percent',
    type: 'collapsible',
    title: 'Gestión de Facturas 3.3',
    translate: 'MENU.INVOICE_PERCENT.INVOICE_MODULE',
    icon: 'file-text',
    role: ['Master', 'InvoiceWebUser', 'MasterUser'],
    children: [
      {
        id: 'invoice/invoice-percent',
        title: 'Gestión de Facturas IEPS 4.0',
        translate: 'MENU.INVOICE_PERCENT.INVOICE',
        type: 'item',
        url: 'invoice/invoice-percent'
      },
      {
        id: 'invoice/invoice-percent-drafts',
        title: 'Borradores Facturas IEPS 4.0',
        translate: 'MENU.INVOICE_PERCENT.INVOICE_DRAFT',
        type: 'item',
        url: 'invoice/invoice-percent-drafts'
      },
      {
        id: 'invoice/invoice-percent-cancelled',
        title: 'Cancelaciones Facturas IEPS 4.0',
        translate: 'MENU.INVOICE_PERCENT.CANCELLED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-percent-cancelled'
      },
      {
        id: 'invoice/invoice-percent-failed',
        title: 'Intentos Fallidos Facturas IEPS 4.0',
        translate: 'MENU.INVOICE_PERCENT.FAILED_INVOICE',
        type: 'item',
        url: 'invoice/invoice-percent-failed'
      }
    ]
  },
  {
    id: 'payroll-previous',
    type: 'collapsible',
    title: 'Gestión de nómina 3.3',
    translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_MODULE',
    icon: 'file-text',
    role: ['Master', 'PayrollWebUser', 'MasterUser'],
    children: [
      {
        id: 'payroll/payroll-v-3',
        title: 'Recibo de Nómina 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-3'
      },
      {
        id: 'payroll/payroll-v-3-drafts',
        title: 'Borradores Nómina 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_DRAFT',
        type: 'item',
        url: 'payroll/payroll-v-3-drafts'
      },
      {
        id: 'payroll/payroll-v-3-cancelled',
        title: 'Nómina Cancelada 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.CANCELLED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-3-cancelled'
      },
      {
        id: 'payroll/payroll-v-3-failed',
        title: 'Intentos Fallidos Nómina 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.FAILED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-3-failed'
      }
    ]
  },
  {
    id: 'payroll',
    type: 'collapsible',
    title: 'Gestión de nómina 4.0',
    translate: 'MENU.PAYROLL.PAYROLL_MODULE',
    icon: 'file-text',
    role: ['Master', 'PayrollWebUser', 'MasterUser'],
    children: [
      {
        id: 'payroll/payroll',
        title: 'Nuevo Recibo 4.0',
        translate: 'MENU.PAYROLL.NEW_PAYROLL',
        type: 'item',
        url: 'payroll/payroll/add'
      },
      {
        id: 'payroll/payroll-v-4',
        title: 'Recibo de Nómina 4.0',
        translate: 'MENU.PAYROLL.PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-4'
      },
      {
        id: 'payroll/payroll-v-4-drafts',
        title: 'Borradores Nómina 4.0',
        translate: 'MENU.PAYROLL.PAYROLL_DRAFT',
        type: 'item',
        url: 'payroll/payroll-v-4-drafts'
      },
      {
        id: 'payroll/payroll-v-4-cancelled',
        title: 'Nómina Cancelada 4.0',
        translate: 'MENU.PAYROLL.CANCELLED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-4-cancelled'
      },
      {
        id: 'payroll/payroll-v-4-failed',
        title: 'Intentos Fallidos Nómina 4.0',
        translate: 'MENU.PAYROLL.FAILED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-v-4-failed'
      }
    ]
  },
  {
    id: 'payroll-multiple',
    type: 'collapsible',
    title: 'Nómina múltiples complementos 4.0',
    translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_MODULE',
    icon: 'file-text',
    role: ['Master', 'PayrollWebUser', 'MasterUser'],
    children: [
      {
        id: 'payroll/payroll-multiple',
        title: 'Nuevo Recibo 4.0',
        translate: 'MENU.PAYROLL.NEW_PAYROLL',
        type: 'item',
        url: 'payroll/multiple-payroll/add'
      },
      {
        id: 'payroll/payroll-multiple',
        title: 'Gestion múltiples complementos 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL',
        type: 'item',
        url: 'payroll/payroll-multiple'
      },
      {
        id: 'payroll/payroll-multiple-drafts',
        title: 'Borradores nómina 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_DRAFT',
        type: 'item',
        url: 'payroll/payroll-multiple-drafts'
      },
      {
        id: 'payroll/payroll-multiple-cancelled',
        title: 'Nómina cancelada 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.CANCELLED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-multiple-cancelled'
      },
      {
        id: 'payroll/payroll-multiple-failed',
        title: 'Intentos fallidos nómina 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.FAILED_PAYROLL',
        type: 'item',
        url: 'payroll/payroll-multiple-failed'
      }
    ]
  },
  {
    id: 'retention-previous',
    type: 'collapsible',
    title: 'Administración Retención 1.0',
    translate: 'MENU.RETENTION_PREVIOUS.RETENTION_MODULE',
    icon: 'file-text',
    role: ['Master', 'RetentionWebUser', 'MasterUser'],
    children: [
      {
        id: 'retention/retention-v-3',
        title: 'Gestion de retenciones 1.0',
        translate: 'MENU.RETENTION_PREVIOUS.RETENTION',
        type: 'item',
        url: 'retention/retention-v-3'
      },
      {
        id: 'retention/retention-v-3-drafts',
        title: 'Borradores Retenciones 1.0',
        translate: 'MENU.RETENTION_PREVIOUS.RETENTION_DRAFT',
        type: 'item',
        url: 'retention/retention-v-3-drafts'
      },
      {
        id: 'retention/retention-v-3-cancelled',
        title: 'Retenciones Canceladas 1.0',
        translate: 'MENU.RETENTION_PREVIOUS.CANCELLED_RETENTION',
        type: 'item',
        url: 'retention/retention-v-3-cancelled'
      },
      {
        id: 'retention/retention-v-3-failed',
        title: 'Intentos Fallidos Retenciones 1.0',
        translate: 'MENU.RETENTION_PREVIOUS.FAILED_RETENTION',
        type: 'item',
        url: 'retention/retention-v-3-failed'
      }
    ]
  },
  {
    id: 'retention-latest',
    type: 'collapsible',
    title: 'Administración Retención 2.0',
    translate: 'MENU.RETENTION.RETENTION_MODULE',
    icon: 'file-text',
    role: ['Master', 'RetentionWebUser', 'MasterUser'],
    children: [
      {
        id: 'retention/receptors',
        title: 'Receptores Retenciones',
        translate: 'MENU.RETENTION.RETENTION_RECEPTOR',
        type: 'item',
        url: 'retention/receptors'
      },
      {
        id: 'retention/retention',
        title: 'Nueva Constancia 2.0',
        translate: 'MENU.RETENTION.RETENTION_NEW',
        type: 'item',
        url: 'retention/retention/add'
      },
      {
        id: 'retention/retention-v-4',
        title: 'Gestion de retenciones 2.0',
        translate: 'MENU.RETENTION.RETENTION',
        type: 'item',
        url: 'retention/retention-v-4'
      },
      {
        id: 'retention/retention-v-4-drafts',
        title: 'Borradores Retenciones 2.0',
        translate: 'MENU.RETENTION.RETENTION_DRAFT',
        type: 'item',
        url: 'retention/retention-v-4-drafts'
      },
      {
        id: 'retention/retention-v-4-cancelled',
        title: 'Retenciones Canceladas 2.0',
        translate: 'MENU.RETENTION.CANCELLED_RETENTION',
        type: 'item',
        url: 'retention/retention-v-4-cancelled'
      },
      {
        id: 'retention/retention-v-4-failed',
        title: 'Intentos Fallidos Retenciones 2.0',
        translate: 'MENU.RETENTION.FAILED_RETENTION',
        type: 'item',
        url: 'retention/retention-v-4-failed'
      }
    ]
  },
  {
    id: 'retention-latest',
    type: 'collapsible',
    title: 'Administración Retención 2.0',
    translate: 'MENU.RETENTION.RETENTION_MODULE',
    icon: 'file-text',
    role: ['RetentionBatchUser'],
    children: [
      {
        id: 'retention/receptors',
        title: 'Receptores Retenciones',
        translate: 'MENU.RETENTION.RETENTION_RECEPTOR',
        type: 'item',
        url: 'retention/receptors'
      }
    ]
  },
  {
    id: 'invoice-batch-v-3',
    type: 'collapsible',
    title: 'Factura Batch 3.3',
    translate: 'MENU.INVOICE_PREVIOUS.INVOICE_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'InvoiceBatchUser', 'MasterUser'],
    children: [
      {
        id: 'invoice-batch/invoice-batch-v-3',
        title: 'Facturas Batch 3.3',
        translate: 'MENU.INVOICE_PREVIOUS.INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-3'
      },
      {
        id: 'invoice-batch/invoice-batch-v-3-cancelled',
        title: 'Cancelaciones Batch',
        translate: 'MENU.INVOICE_PREVIOUS.CANCELLED_INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-3-cancelled'
      },
      {
        id: 'invoice-batch/invoice-batch-v-3-failed',
        title: 'Intentos Fallidos Batch',
        translate: 'MENU.INVOICE_PREVIOUS.FAILED_INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-3-failed'
      }
    ]
  },
  {
    id: 'invoice-batch-v-4',
    type: 'collapsible',
    title: 'Factura Batch 4.0',
    translate: 'MENU.INVOICE.INVOICE_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'InvoiceBatchUser', 'MasterUser'],
    children: [
      {
        id: 'invoice-batch/invoice-batch-add',
        title: 'Nueva Factura Batch 4.0',
        translate: 'MENU.INVOICE.INVOICE_BATCH_ADD',
        type: 'item',
        url: 'invoice-batch/invoice-batch-add'
      },
      {
        id: 'invoice-batch/invoice-batch-percent-add',
        title: 'Nueva Factura IEPS Batch 4.0',
        translate: 'MENU.INVOICE.INVOICE_BATCH_IEPS_ADD',
        type: 'item',
        url: 'invoice-batch/invoice-batch-percent-add'
      },
      {
        id: 'invoice-batch/invoice-batch-v-4',
        title: 'Facturas Batch 4.0',
        translate: 'MENU.INVOICE.INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-4'
      },
      {
        id: 'invoice-batch/invoice-batch-v-4-cancelled',
        title: 'Cancelaciones Batch 4.0',
        translate: 'MENU.INVOICE.CANCELLED_INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-4-cancelled'
      },
      {
        id: 'invoice-batch/invoice-batch-v-4-failed',
        title: 'Intentos Fallidos Batch 4.0',
        translate: 'MENU.INVOICE.FAILED_INVOICE_BATCH',
        type: 'item',
        url: 'invoice-batch/invoice-batch-v-4-failed'
      }
    ]
  },
  {
    id: 'payroll-batch-v-3',
    type: 'collapsible',
    title: 'Nómina Batch 3.3',
    translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'PayrollBatchUser', 'MasterUser'],
    children: [
      {
        id: 'payroll-batch/payroll-batch-v-3',
        title: 'Nómina Batch 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_BATCH',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-3'
      },
      {
        id: 'payroll-batch/payroll-batch-v-3-cancelled',
        title: 'Cancelaciones Batch 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_BATCH_CANCELLED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-3-cancelled'
      },
      {
        id: 'payroll-batch/payroll-batch-v-3-failed',
        title: 'Intentos Fallidos Batch 3.3',
        translate: 'MENU.PAYROLL_PREVIOUS.PAYROLL_BATCH_FAILED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-3-failed'
      }
    ]
  },
  {
    id: 'payroll-batch-v-4',
    type: 'collapsible',
    title: 'Nómina Batch 4.0',
    translate: 'MENU.PAYROLL.PAYROLL_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'PayrollBatchUser', 'MasterUser'],
    children: [
      {
        id: 'payroll-batch/payroll-batch-add',
        title: 'Nuevo Recibo Batch 4.0',
        translate: 'MENU.PAYROLL.PAYROLL_BATCH_ADD',
        type: 'item',
        url: 'payroll-batch/payroll-batch-add'
      },
      {
        id: 'payroll-batch/payroll-batch-v-4',
        title: 'Nómina Batch 4.0',
        translate: 'MENU.PAYROLL.PAYROLL_BATCH',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-4'
      },
      {
        id: 'payroll-batch/payroll-batch-v-4-cancelled',
        title: 'Cancelaciones Batch 4.0',
        translate: 'MENU.PAYROLL.PAYROLL_BATCH_CANCELLED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-4-cancelled'
      },
      {
        id: 'payroll-batch/payroll-batch-v-4-failed',
        title: 'Intentos Fallidos Batch 4.0',
        translate: 'MENU.PAYROLL.PAYROLL_BATCH_FAILED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-v-4-failed'
      }
    ]
  },
  {
    id: 'payroll-batch-multiple',
    type: 'collapsible',
    title: 'Nómina Batch 4.0',
    translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'PayrollBatchUser', 'MasterUser'],
    children: [
      {
        id: 'payroll-batch/payroll-batch-multiple-add',
        title: 'Nuevo Recibo Batch 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_BATCH_ADD',
        type: 'item',
        url: 'payroll-batch/payroll-batch-multiple-add'
      },
      {
        id: 'payroll-batch/payroll-batch-multiple',
        title: 'Nómina Batch 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_BATCH',
        type: 'item',
        url: 'payroll-batch/payroll-batch-multiple'
      },
      {
        id: 'payroll-batch/payroll-batch-multiple-cancelled',
        title: 'Cancelaciones Batch 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_BATCH_CANCELLED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-multiple-cancelled'
      },
      {
        id: 'payroll-batch/payroll-batch-multiple-failed',
        title: 'Intentos Fallidos Batch 4.0',
        translate: 'MENU.PAYROLL_MULTIPLE.PAYROLL_BATCH_FAILED',
        type: 'item',
        url: 'payroll-batch/payroll-batch-multiple-failed'
      }
    ]
  },
  {
    id: 'retention-batch-v-3',
    type: 'collapsible',
    title: 'Factura Batch 3.3',
    translate: 'MENU.RETENTION_PREVIOUS.RETENTION_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'RetentionBatchUser', 'MasterUser'],
    children: [
      {
        id: 'retention-batch/retention-batch-v-3',
        title: 'Facturas Batch 3.3',
        translate: 'MENU.RETENTION_PREVIOUS.RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-3'
      },
      {
        id: 'retention-batch/retention-batch-v-3-cancelled',
        title: 'Cancelaciones Batch',
        translate: 'MENU.RETENTION_PREVIOUS.CANCELLED_RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-3-cancelled'
      },
      {
        id: 'retention-batch/retention-batch-v-3-failed',
        title: 'Intentos Fallidos Batch',
        translate: 'MENU.RETENTION_PREVIOUS.FAILED_RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-3-failed'
      }
    ]
  },
  {
    id: 'retention-batch-v-4',
    type: 'collapsible',
    title: 'Factura Batch 4.0',
    translate: 'MENU.RETENTION.RETENTION_BATCH_MODULE',
    icon: 'copy',
    role: ['Master', 'RetentionBatchUser', 'MasterUser'],
    children: [
      {
        id: 'retention-batch/retention-batch-add',
        title: 'Nueva Constancia Batch 2.0',
        translate: 'MENU.RETENTION.RETENTION_BATCH_ADD',
        type: 'item',
        url: 'retention-batch/retention-batch-add'
      },
      {
        id: 'retention-batch/retention-batch-v-4',
        title: 'Facturas Batch 4.0',
        translate: 'MENU.RETENTION.RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-4'
      },
      {
        id: 'retention-batch/retention-batch-v-4-cancelled',
        title: 'Cancelaciones Batch 4.0',
        translate: 'MENU.RETENTION.CANCELLED_RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-4-cancelled'
      },
      {
        id: 'retention-batch/retention-batch-v-4-failed',
        title: 'Intentos Fallidos Batch 4.0',
        translate: 'MENU.RETENTION.FAILED_RETENTION_BATCH',
        type: 'item',
        url: 'retention-batch/retention-batch-v-4-failed'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['Master', 'MasterUser'],
    children: [
      {
        id: 'report/download-invoice',
        title: 'Descarga facturas web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_INVOICE_TITLE',
        type: 'item',
        url: 'report/download-invoice'
      },
      {
        id: 'report/download-invoice-batch',
        title: 'Descarga facturas batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_INVOICE_BATCH_TITLE',
        type: 'item',
        url: 'report/download-invoice-batch'
      },
      {
        id: 'report/download-payroll',
        title: 'Descarga nómina web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_PAYROLL_TITLE',
        type: 'item',
        url: 'report/download-payroll'
      },
      {
        id: 'report/download-payroll-batch',
        title: 'Descarga nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_PAYROLL_BATCH_TITLE',
        type: 'item',
        url: 'report/download-payroll-batch'
      },
      {
        id: 'report/download-retention',
        title: 'Descarga retenciones web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_RETENTION_TITLE',
        type: 'item',
        url: 'report/download-retention'
      },
      {
        id: 'report/download-retention-batch',
        title: 'Descarga retenciones batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_RETENTION_BATCH_TITLE',
        type: 'item',
        url: 'report/download-retention-batch'
      },
      {
        id: 'report/payroll-batch-report',
        title: 'Reporte nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.PAYROLL_BATCH_REPORT',
        type: 'item',
        url: 'report/payroll-batch-report'
      },
      {
        id: 'report/payroll-report',
        title: 'Reporte nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.PAYROLL_REPORT',
        type: 'item',
        url: 'report/payroll-report'
      },
      {
        id: 'report/multiple-payroll-batch-report',
        title: 'Reporte nómina global batch',
        translate: 'MENU.CUSTOMER_REPORT.MULTIPLE_PAYROLL_BATCH_REPORT',
        type: 'item',
        url: 'report/multiple-payroll-batch-report'
      },
      {
        id: 'report/multiple-payroll-report',
        title: 'Reporte nómina global web',
        translate: 'MENU.CUSTOMER_REPORT.MULTIPLE_PAYROLL_REPORT',
        type: 'item',
        url: 'report/multiple-payroll-report'
      },
      {
        id: 'report/retention-batch-report',
        title: 'Reporte retenciones batch',
        translate: 'MENU.CUSTOMER_REPORT.RETENTION_BATCH_REPORT',
        type: 'item',
        url: 'report/retention-batch-report'
      },
      {
        id: 'report/retention-report',
        title: 'Reporte retenciones web',
        translate: 'MENU.CUSTOMER_REPORT.RETENTION_REPORT',
        type: 'item',
        url: 'report/retention-report'
      },
      {
        id: 'report/invoice-batch-report',
        title: 'Reporte facturas batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-batch-report'
      },
      {
        id: 'report/invoice-report',
        title: 'Reporte facturas web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_REPORT',
        type: 'item',
        url: 'report/invoice-report'
      },
      {
        id: 'report/invoice-payment-batch-report',
        title: 'Reporte factura pagos batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PAYMENT_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-payment-batch-report'
      },
      {
        id: 'report/invoice-payment-report',
        title: 'Reporte Factura pagos web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PAYMENT_REPORT',
        type: 'item',
        url: 'report/invoice-payment-report'
      },
      {
        id: 'report/invoice-premium-batch-report',
        title: 'Reporte plus pagos batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PREMIUM_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-premium-batch-report'
      },
      {
        id: 'report/invoice-premium-report',
        title: 'Reporte plus pagos web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PREMIUM_REPORT',
        type: 'item',
        url: 'report/invoice-premium-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['InvoiceWebUser'],
    children: [
      {
        id: 'report/download-invoice',
        title: 'Descarga facturas web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_INVOICE_TITLE',
        type: 'item',
        url: 'report/download-invoice'
      },
      {
        id: 'report/invoice-report',
        title: 'Reporte facturas web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_REPORT',
        type: 'item',
        url: 'report/invoice-report'
      },
      {
        id: 'report/invoice-payment-report',
        title: 'Reporte Factura pagos web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PAYMENT_REPORT',
        type: 'item',
        url: 'report/invoice-payment-report'
      },
      {
        id: 'report/invoice-premium-report',
        title: 'Reporte plus pagos web',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PREMIUM_REPORT',
        type: 'item',
        url: 'report/invoice-premium-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['PayrollWebUser'],
    children: [
      {
        id: 'report/download-payroll',
        title: 'Descarga nómina web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_PAYROLL_TITLE',
        type: 'item',
        url: 'report/download-payroll'
      },
      {
        id: 'report/payroll-report',
        title: 'Reporte nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.PAYROLL_REPORT',
        type: 'item',
        url: 'report/payroll-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['RetentionWebUser'],
    children: [
      {
        id: 'report/download-retention',
        title: 'Descarga retenciones web',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_RETENTION_TITLE',
        type: 'item',
        url: 'report/download-retention'
      },
      {
        id: 'report/retention-report',
        title: 'Reporte retenciones web',
        translate: 'MENU.CUSTOMER_REPORT.RETENTION_REPORT',
        type: 'item',
        url: 'report/retention-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['InvoiceBatchUser'],
    children: [
      {
        id: 'report/download-invoice-batch',
        title: 'Descarga facturas batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_INVOICE_BATCH_TITLE',
        type: 'item',
        url: 'report/download-invoice-batch'
      },
      {
        id: 'report/invoice-batch-report',
        title: 'Reporte facturas batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-batch-report'
      },
      {
        id: 'report/invoice-payment-batch-report',
        title: 'Reporte factura pagos batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PAYMENT_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-payment-batch-report'
      },
      {
        id: 'report/invoice-premium-batch-report',
        title: 'Reporte plus pagos batch',
        translate: 'MENU.CUSTOMER_REPORT.INVOICE_PREMIUM_BATCH_REPORT',
        type: 'item',
        url: 'report/invoice-premium-batch-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['PayrollBatchUser'],
    children: [
      {
        id: 'report/download-payroll-batch',
        title: 'Descarga nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_PAYROLL_BATCH_TITLE',
        type: 'item',
        url: 'report/download-payroll-batch'
      },
      {
        id: 'report/payroll-batch-report',
        title: 'Reporte nómina batch',
        translate: 'MENU.CUSTOMER_REPORT.PAYROLL_BATCH_REPORT',
        type: 'item',
        url: 'report/payroll-batch-report'
      }
    ]
  },
  {
    id: 'report',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.CUSTOMER_REPORT.REPORT',
    icon: 'map',
    role: ['RetentionBatchUser'],
    children: [
      {
        id: 'report/download-retention-batch',
        title: 'Descarga retenciones batch',
        translate: 'MENU.CUSTOMER_REPORT.DOWNLOAD_RETENTION_BATCH_TITLE',
        type: 'item',
        url: 'report/download-retention-batch'
      },
      {
        id: 'report/retention-batch-report',
        title: 'Reporte retenciones batch',
        translate: 'MENU.CUSTOMER_REPORT.RETENTION_BATCH_REPORT',
        type: 'item',
        url: 'report/retention-batch-report'
      },
    ]
  },
  {
    id: 'configuration',
    type: 'collapsible',
    title: 'Configuración',
    translate: 'MENU.SETTING.SECTION',
    icon: 'settings',
    role: ['Master', 'InvoiceWebUser', 'InvoiceBatchUser', 'MasterUser'],
    children: [
      {
        id: 'configuration/companies',
        title: 'Empresa',
        translate: 'MENU.COMPANY.SECTION',
        type: 'item',
        url: 'configuration/companies'
      },
      {
        id: 'configuration/customers',
        title: 'Cliente',
        translate: 'MENU.CUSTOMER.SECTION',
        type: 'item',
        url: 'configuration/customers'
      },
      {
        id: 'configuration/products',
        title: 'Productos',
        translate: 'MENU.PRODUCT.SECTION',
        type: 'item',
        url: 'configuration/products'
      },
      {
        id: 'configuration/taxes',
        title: 'Administrar impuestos',
        translate: 'MENU.TAX.SECTION',
        type: 'item',
        url: 'configuration/taxes'
      },
      {
        id: 'configuration/agendas',
        title: 'Addenda',
        translate: 'MENU.ADDENDA.SECTION',
        type: 'item',
        url: 'configuration/agendas'
      },
      {
        id: 'configuration/parts',
        title: 'Parte',
        translate: 'MENU.PART.SECTION',
        type: 'item',
        url: 'configuration/parts'
      },
    ]
  },
  {
    id: 'configuration',
    type: 'collapsible',
    title: 'Configuración',
    translate: 'MENU.SETTING.SECTION',
    icon: 'settings',
    role: ['PayrollWebUser', 'RetentionWebUser', 'PayrollBatchUser', 'RetentionBatchUser'],
    children: [
      {
        id: 'configuration/companies',
        title: 'Empresa',
        translate: 'MENU.COMPANY.SECTION',
        type: 'item',
        url: 'configuration/companies'
      }
    ]
  },
  {
    id: 'employee',
    type: 'collapsible',
    title: 'Administración de Empleados',
    translate: 'MENU.EMPLOYEE.SECTION',
    icon: 'settings',
    role: ['Master', 'PayrollWebUser', 'PayrollBatchUser', 'MasterUser'],
    children: [
      {
        id: 'employee/employees',
        title: 'Empresa',
        translate: 'MENU.EMPLOYEE.EMPLOYEE.SECTION',
        type: 'item',
        url: 'employee/employees'
      },
      {
        id: 'employee/departments',
        title: 'Cliente',
        translate: 'MENU.EMPLOYEE.DEPARTMENT.SECTION',
        type: 'item',
        url: 'employee/departments'
      },
      {
        id: 'employee/expenses',
        title: 'Addenda',
        translate: 'MENU.EMPLOYEE.EXPENSE.SECTION',
        type: 'item',
        url: 'employee/expenses'
      },
      {
        id: 'employee/discounts',
        title: 'Administrar impuestos',
        translate: 'MENU.EMPLOYEE.DISCOUNT.SECTION',
        type: 'item',
        url: 'employee/discounts'
      },
      {
        id: 'employee/others',
        title: 'Productos',
        translate: 'MENU.EMPLOYEE.OTHER.SECTION',
        type: 'item',
        url: 'employee/others'
      }
    ]
  },
  {
    id: 'operator',
    type: 'item',
    title: 'Administrador de Usuarios',
    translate: 'MENU.OPERATOR.TITLE',
    icon: 'user',
    role: ['Master'],
    url: 'operator/operators'
  },
  {
    id: 'report1',
    type: 'collapsible',
    title: 'Reportes',
    translate: 'MENU.REPORT.SECTION',
    icon: 'map',
    role: ['Admin'],
    children: [
      {
        id: 'automatic-invoice-report',
        title: 'Reporte F.A.',
        translate: 'MENU.REPORT.AUTOMATIC_INVOICE_REPORT.SECTION',
        type: 'item',
        url: 'report/automatic-invoice-report'
      },
      {
        id: 'report',
        title: 'Reporte pago F.A.',
        translate: 'MENU.REPORT.AUTOMIATIC_INVOICE_PAYMENT_REPORT.SECTION',
        type: 'item',
        url: 'report/automatic-payment-invoice-report'
      },
      {
        id: 'report',
        title: 'Descarga F.A.',
        translate: 'MENU.REPORT.AUTOMATIC_INVOICE_DOWNLOAD.SECTION',
        type: 'item',
        url: 'report/automatic-invoice-download'
      },
      {
        id: 'report',
        title: 'Report Fallidos F.A.',
        translate: 'MENU.REPORT.AUTOMATIC_INVOICE_FAILED_REPORT.SECTION',
        type: 'item',
        url: 'report/automatic-invoice-failed-report'
      },
      {
        id: 'report',
        title: 'Reporte especial',
        translate: 'MENU.REPORT.AUTOMATIC_INVOICE_SPECIAL_REPORT.SECTION',
        type: 'item',
        url: 'report/special-report'
      }
    ]
  },
];
    // localStorage.getItem('types') == 0 ?
      //  :
    /*[{
      id: 'dashboard',
      title: 'Dashboards',
      translate: 'MENU.DASHBOARD.COLLAPSIBLE',
      types: 'item',
      role: ['Admin'], // ? To hide collapsible based on user role
      icon: 'home',
      url: 'dashboard',
      children: []
    }];*/
