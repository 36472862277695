import { Role } from './role';


export class User {
  id: number;
  master: {
    activate_premium: Number,
    activate_premium_api: Number,
    active_api: Number,
    active_cfdi: Number,
    active_manifest: Number,
    active_payment: Number,
    api_documents: Number,
    cfdi_documents: Number,
    manifest: Number,
    payment_documents: Number,
    web_service_id: Number
  };
  role: Role;
  token?: string;
  user: {
    name: string,
    type: Number
  };
}
