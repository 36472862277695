export enum Role {
  Admin = 'Admin',
  Master = 'Master',
  InvoiceWebUser = 'InvoiceWebUser',
  PayrollWebUser = 'PayrollWebUser',
  RetentionWebUser = 'RetentionWebUser',
  InvoiceBatchUser = 'InvoiceBatchUser',
  PayrollBatchUser = 'PayrollBatchUser',
  RetentionBatchUser = 'RetentionBatchUser',
  MasterUser = 'MasterUser'
}
