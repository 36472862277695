import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // public
  public currentUser: Observable<User>;

  // private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private trans: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is Master
   */
  get isMaster() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Master;
  }


  /**
   *  Confirms if user is InvoiceWebUser
   */
   get isInvoiceWebUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.InvoiceWebUser;
  }

  /**
   *  Confirms if user is PayrollWebUser
   */
   get isPayrollWebUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.PayrollWebUser;
  }


  /**
   *  Confirms if user is RetentionWebUser
   */
   get isRetentionWebUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.RetentionWebUser;
  }


  /**
   *  Confirms if user is InvoiceBatchUser
   */
   get isInvoiceBatchUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.InvoiceBatchUser;
  }

  /**
   *  Confirms if user is PayrollBatchUser
   */
   get isPayrollBatchUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.PayrollBatchUser;
  }


  /**
   *  Confirms if user is RetentionBatchUser
   */
   get isRetentionBatchUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.RetentionBatchUser;
  }


  /**
   *  Confirms if user is MasterUser
   */
   get isMasterUser() {
    return this.currentUser && this.currentUserSubject.value.role === Role.MasterUser;
  }

  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.appApiUrl}api/login`, { email, password })
      .pipe(
        map((user: any) => {
            // console.log('USER++', user);
            if (user.success && user.token != null) {
                // login successful if there's a jwt token in the response
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // Master
                // InvoiceWebUser
                // PayrollWebUser
                // RetentionWebUser
                // InvoiceBatchUser
                // PayrollBatchUser
                // RetentionBatchUser
                // MasterUser

                // tslint:disable-next-line:triple-equals max-line-length
                user.role = (user.user.type == 0 ? 'Admin' : (user.user.type == 1 ? 'Master' : (user.user.type == 2 ? 'InvoiceWebUser' : (user.user.type == 3 ? 'PayrollWebUser' : (user.user.type == 4 ? 'RetentionWebUser' : (user.user.type == 5 ? 'InvoiceBatchUser' : (user.user.type == 6 ? 'PayrollBatchUser' : (user.user.type == 7 ? 'RetentionBatchUser' : (user.user.type == 8 ? 'MasterUser' : '')))))))));
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('type', user.user.type);

                if (user.user.type > 0) {
                    localStorage.setItem('num_cfdi', user.master.cfdi_documents);
                    localStorage.setItem('num_api', user.master.api_documents);
                    localStorage.setItem('active_cfdi', user.master.active_cfdi);
                    localStorage.setItem('active_api', user.master.active_api);
                    localStorage.setItem('active_manifest', user.master.active_manifest);
                    localStorage.setItem('activate_premium', user.master.activate_premium);
                    localStorage.setItem('activate_premium_api', user.master.activate_premium_api);
                    localStorage.setItem('active_multiple_payroll', user.master.active_multiple_payroll);
                    localStorage.setItem('active_multiple_payroll_batch', user.master.active_multiple_payroll_batch);
                    localStorage.setItem('active_invoice_with_percent', user.master.active_invoice_with_percent);
                    localStorage.setItem('active_invoice_with_percent_api', user.master.active_invoice_with_percent_api);
                }

                // Display welcome toast!
                setTimeout(() => {
                    this._toastrService.success(
                        this.trans.instant('LOGIN.SUCCESSFULLY') + '', // as an ' + user.role + '!',
                        '👋 '  + this.trans.instant('LOGIN.WELCOME') + ', ' + user.user.name + '!',
                        { toastClass: 'toast ngx-toastr', closeButton: true }
                    );
                }, 2500);

                // notify
                this.currentUserSubject.next(user);
            }

            return user;
        })
      );
  }

  register(data: any) {
    return this._http
    .post<any>(`${environment.appApiUrl}register`, data)
    .pipe(
      map(result => {// notify
        // this.currentUserSubject.next(user);
        // console.log("user",result);
        if (result.success) {
          setTimeout(() => {
            this._toastrService.success(
                this.trans.instant('LOGIN.REGISTERED_SUCCESSFULLY') + '',
                this.trans.instant('REGISTER.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);
        }
      return result;
    })
    );
  }
  forgetPassword(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };    return this._http
    .post<any>(`${environment.appApiUrl}password-recovery`, data, httpOptions)
    .pipe(
      map(result => {// notify
        // this.currentUserSubject.next(user);
        console.log('user', result);
        if (result.success) {
          setTimeout(() => {
            this._toastrService.success(
                this.trans.instant('LOGIN.REGISTERED_SUCCESSFULLY') + '',
                this.trans.instant('REGISTER.TITLE'),
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);
        }
      return result;
    })
    );
  }
  // forgetPassword(data:any){
  //   return this._http
  //   .post<any>(`${environment.appApiUrl}password-recovery`, data)
  //   .pipe(
  //     map(result => {// notify
  //       // this.currentUserSubject.next(user);
  //       console.log("user",result);
  //       if(result.success){
  //         setTimeout(() => {
  //           this._toastrService.success(
  //             'Please check your email!',
  //             'Forget Password',
  //             { toastClass: 'toast ngx-toastr', closeButton: true }
  //           );
  //         }, 2500);
  //       }
  //     return result;
  //   })
  //   );
  // }

  async checkUsername(data: any) {
    const headOptDetails: any = {
      'Accept': 'application/json'
    };

    return await this._http
    .get<any>(`${environment.appApiUrl}check-username/` + data + `/0`, { headers: headOptDetails }).toPromise();
  }

  async checkRfc(data: any) {
    const headOptDetails: any = {
      'Accept': 'application/json'
    };
    return await this._http
    .get<any>(`${environment.appApiUrl}check-rfc/` + data, { headers: headOptDetails }).toPromise();
  }
  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
